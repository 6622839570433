import React from "react";
import Carousel from 'react-bootstrap/Carousel';
// import ProgressBar from 'react-bootstrap/ProgressBar';

function Employment() {
  return (
    <div>
      <main id="main">
        {/* ======= Resume Section ======= */}
        <section id="resume" className="resume">
          <div className="container">
            <div className="section-title">
              <h2>EMPLOYMENT</h2>
              {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
            </div>
            <div className="row">
              {/* <div className="col-lg-6" data-aos="fade-up">
                <h3 className="resume-title">Sumary</h3>
                <div className="resume-item pb-0">
                  <h4>Alex Smith</h4>
                  <p><em>Innovative and deadline-driven Graphic Designer with 3+ years of experience designing and developing user-centered digital/print marketing material from initial concept to final, polished deliverable.</em></p>
                  <ul>
                    <li>Portland par 127,Orlando, FL</li>
                    <li>(123) 456-7891</li>
                    <li>alice.barkley@example.com</li>
                  </ul>
                </div>
                <h3 className="resume-title">Education</h3>
                <div className="resume-item">
                  <h4>Master of Fine Arts &amp; Graphic Design</h4>
                  <h5>2015 - 2016</h5>
                  <p><em>Rochester Institute of Technology, Rochester, NY</em></p>
                  <p>Qui deserunt veniam. Et sed aliquam labore tempore sed quisquam iusto autem sit. Ea vero voluptatum qui ut dignissimos deleniti nerada porti sand markend</p>
                </div>
                <div className="resume-item">
                  <h4>Bachelor of Fine Arts &amp; Graphic Design</h4>
                  <h5>2010 - 2014</h5>
                  <p><em>Rochester Institute of Technology, Rochester, NY</em></p>
                  <p>Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae consequatur neque etlon sader mart dila</p>
                </div>
              </div> */}
              <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
                <h3 className="resume-title">Professional Experience</h3>
                <div className="resume-item">
                  <h4>Software Development Engineer</h4>
                  <h5>2-Jan-2024 - 7-Oct-2024</h5>
                  <p><em><a style={{ color: "black" }} target="_blank" href="https://jaijotech.com/">Jai Jo Tech Private Limited</a>, Chennai</em></p>
                  <ul>
                    <li>Software Development</li>
                    <li>End User Testing</li>
                    <li>Products Management</li>
                    <li>Cloud Server Management</li>
                  </ul>
                </div>
                <div className="resume-item">
                  <h4>Web Developer</h4>
                  <h5>3-Jan-2022 - 30-Dec-2023</h5>
                  <p><em><a style={{ color: "black" }} target="_blank" href="https://digidrips.com/">Digidrips Business Solutions</a>, Chennai</em></p>
                  <ul>
                    <li>Website Development, Customizing pre-designed free and paid website templates</li>
                    <li>HTML, CSS, Bootstrap, Java Script, JQuery</li>
                    <li>Adobe Photoshop</li>
                    <li>Logo Designing</li>
                    <li>Web Domain, Web Hosting, Server Mangement</li>
                    <li>Content Writing</li>
                    <li>Project and Team Management</li>
                  </ul>
                </div>
                <div className="resume-item">
                  <h4>Web Developer</h4>
                  <h5>4-Jan-2021 upto 31-Dec-2021</h5>
                  <p><em><a style={{ color: "black" }} target="_blank" href="https://www.igonsoft.com/">IgonSoft</a>, Chennai</em></p>
                  <ul>
                    <li>Website Development, Customizing pre-designed free and paid website templates</li>
                    <li>HTML, CSS, Bootstrap, Java Script</li>
                    <li>Adobe Photoshop</li>
                  </ul>
                </div>
                <div className="resume-item">
                  <h4>Digital Marketing Manager</h4>
                  <h5>1-Apr-2019 upto 31-Dec-2020</h5>
                  <p><em><a style={{ color: "black" }} target="_blank" href="https://altusquantum.com/">Altus Quantum Business Solutions Private Limited</a>, Chennai</em></p>
                  <ul>
                    <li>Search Engine Optimization</li>
                    <li>Search Engine Marketing</li>
                    <li>Social Media Optimization</li>
                    <li>Social Media Marketing</li>
                    <li>Email Marketing</li>
                    <li>Digital Marketing Team Management</li>
                  </ul>
                </div>
                <div className="resume-item">
                  <h4>Digital Marketing Lead</h4>
                  <h5>1-Aug-2017  upto 30-Mar-2019</h5>
                  <p><em><a style={{ color: "black" }} target="_blank" href="https://techsv.in/">SV International Technologies</a>, Chennai</em></p>
                  <ul>
                    <li>Lead Generations</li>
                    <li>Search Engine Marketing</li>
                    <li>Social Media Marketing</li>
                    <li>Digital Marketing Team Leading</li>
                  </ul>
                </div>
                <div className="resume-item">
                  <h4>Senior Digital Marketing Executive</h4>
                  <h5>4-May-2012  upto 31-July-2017</h5>
                  <p><em><a style={{ color: "black" }} target="_blank" href="https://intelliers.com/">Intelliers Global Services</a>, Chennai</em></p>
                  <ul>
                    <li>Search Engine Optimization</li>
                    <li>Search Engine Marketing</li>
                    <li>Social Media Optimization</li>
                    <li>Social Media Marketing</li>
                    <li>Content Writing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======= Testimonials Section ======= */}
        {/* <section id="testimonials" className="testimonials section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Testimonials</h2>
              <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>
            <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
              <div className="swiper-wrapper">
                <Carousel>
                  <Carousel.Item>
                    <div className="swiper-slide">
                      <div className="testimonial-item" data-aos="fade-up">
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                          Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                          <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" />
                        <h3>Saul Goodman</h3>
                        <h4>Ceo &amp; Founder</h4>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="swiper-slide">
                      <div className="testimonial-item" data-aos="fade-up" data-aos-delay="100">
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                          Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                          <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" />
                        <h3>Sara Wilsson</h3>
                        <h4>Designer</h4>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="swiper-slide">
                      <div className="testimonial-item" data-aos="fade-up" data-aos-delay="200">
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                          Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                          <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt="" />
                        <h3>Jena Karlis</h3>
                        <h4>Store Owner</h4>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="swiper-slide">
                      <div className="testimonial-item" data-aos="fade-up" data-aos-delay="300">
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                          Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                          <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt="" />
                        <h3>Matt Brandon</h3>
                        <h4>Freelancer</h4>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="swiper-slide">
                      <div className="testimonial-item" data-aos="fade-up" data-aos-delay="400">
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                          Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                          <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-5.jpg" className="testimonial-img" alt="" />
                        <h3>John Larson</h3>
                        <h4>Entrepreneur</h4>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </section> */}
        {/* ======= Portfolio Details Section ======= */}
        {/* <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-8">
                <div className="portfolio-details-slider swiper">
                  <div className="swiper-wrapper align-items-center">
                    <Carousel>
                      <Carousel.Item>
                        <div className="swiper-slide">
                          <img src="assets/img/portfolio/portfolio-details-1.jpg" alt="" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="swiper-slide">
                          <img src="assets/img/portfolio/portfolio-details-2.jpg" alt="" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="swiper-slide">
                          <img src="assets/img/portfolio/portfolio-details-3.jpg" alt="" />
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio-info">
                  <h3>Project information</h3>
                  <ul>
                    <li><strong>Category</strong>: Web design</li>
                    <li><strong>Client</strong>: ASU Company</li>
                    <li><strong>Project date</strong>: 01 March, 2020</li>
                    <li><strong>Project URL</strong>: <a href="#">www.example.com</a></li>
                  </ul>
                </div>
                <div className="portfolio-description">
                  <h2>This is an example of portfolio detail</h2>
                  <p>
                    Autem ipsum nam porro corporis rerum. Quis eos dolorem eos itaque inventore commodi labore quia quia. Exercitationem repudiandae officiis neque suscipit non officia eaque itaque enim. Voluptatem officia accusantium nesciunt est omnis tempora consectetur dignissimos. Sequi nulla at esse enim cum deserunt eius.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center"><a target="_blank" href="https://ashokkumargd.digidrips.com/Resume/Resume%20-%20Ashokkumar%20G%20D.pdf" ><button type="downoad">RESUME <i class='bx bxs-download'></i></button></a></div>
        </section> */}
      </main>
    </div >
  );
}

export default Employment;
