import React from "react";

function Projects() {
  return (
    <div>
      <main id="main">
        {/* ======= Portfolio Section ======= */}
        {/* <section id="portfolio" className="portfolio section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Portfolio</h2>
              <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">All</li>
                  <li data-filter=".filter-app">App</li>
                  <li data-filter=".filter-card">Card</li>
                  <li data-filter=".filter-web">Web</li>
                </ul>
              </div>
            </div>
            <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-1.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-2.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 3"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-3.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 2"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-4.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-5.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 2"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-6.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 3"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-7.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 1"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-8.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 3"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-9.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 3"><i className="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* ======= Projects Section ======= */}
        <section id="services" className="services">
          <div className="container">
            <div className="section-title">
              <h2>PROJECTS</h2>
              <br></br>
              <div style={{ backgroundColor: "black" }}>
                <center><h4 style={{ color: "white" }}>SOFTWARE DEVELOPMENT</h4></center>
              </div>
              </div>
              <div className="section-title">
              <h3 className="resume-title"><b>Jai Jo Tech Private Limited</b></h3>
              </div>
              <div className="row">
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://jaijotech.com/">jaijotech.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://finla.in/">finla.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://panda.digidrips.com/">Panda</a></h4>
              </div>
              </div>
              <hr></hr>
              <div className="section-title">
              <h3 className="resume-title"><b>Digidrips Business Solutions</b></h3>
              </div>
              {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
            {/* <div className="row">
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-briefcase"></i></div>
                <h4 className="title"><a href="">Lorem Ipsum</a></h4>
                <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                <div className="icon"><i className="bi bi-card-checklist"></i></div>
                <h4 className="title"><a href="">Dolor Sitema</a></h4>
                <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                <div className="icon"><i className="bi bi-bar-chart"></i></div>
                <h4 className="title"><a href="">Sed ut perspiciatis</a></h4>
                <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                <div className="icon"><i className="bi bi-binoculars"></i></div>
                <h4 className="title"><a href="">Magni Dolores</a></h4>
                <p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                <div className="icon"><i className="bi bi-brightness-high"></i></div>
                <h4 className="title"><a href="">Nemo Enim</a></h4>
                <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                <div className="icon"><i className="bi bi-calendar4-week"></i></div>
                <h4 className="title"><a href="">Eiusmod Tempor</a></h4>
                <p className="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://digidrips.com/">digidrips.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://altusquantum.com/">altusquantum.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://quantumcorp.in/">quantumcorp.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://aakam.in/">aakam.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://ammabiriyani.in/">ammabiriyani.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://chennaiwaterheaterservice.com/">chennaiwaterheaterservice.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://cipsc.in/">cipsc.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://dhandapanisas.com/">dhandapanisas.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://vachieversjuniorcollege.in/">vachieversjuniorcollege.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://evpstarscity.com/">evpstarscity.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://js-enterprise.in/">js-enterprise.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://j4associateslegalsolutions.in/">j4associateslegalsolutions.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://maxsystems.co.in/">maxsystems.co.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://saisri-acservices.in/">saisri-acservices.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://newdimensionconsultancy.com/">newdimensionconsultancy.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://shankar-cabs.com/">shankar-cabs.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://smartcoolac.in/">smartcoolac.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://shreevenkateshwaradevelopers.com/">shreevenkateshwaradevelopers.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://spagalaxy.in/">spagalaxy.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://speedservice.in/">speedservice.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://tamilnadubookofrecords.com/">tamilnadubookofrecords.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://unisyncllc.com/">unisyncllc.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://uterinetransplant.com/">uterinetransplant.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://vachieversgroupofcompanies.in/">vachieversgroupofcompanies.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://vachieversiasacademy.com/">vachieversiasacademy.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://drpadmapriya.in/">drpadmapriya.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://waterheaterserviceschennai.com/">waterheaterserviceschennai.com</a></h4>
              </div>
            </div>
            <div className="section-title">
              <center><h6 className="resume-title"><b>NOTE : </b>If the above websites are not live in respective web domain, please click the below button.</h6></center>
              <div className="text-center"><a target="_blank" href="https://ashokkumargd.digidrips.com/Website%20Projects/" ><button type="projects">PROJECTS <i class='bx bx-globe'></i></button></a></div>
            </div>
            <hr></hr>
            <div className="section-title">
              <h3 className="resume-title"><b>IgonSoft</b></h3>
              {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://igonsoft.com/">igonsoft.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://mware.in/">mware.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://aquagenie.in/">aquagenie.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://zebrafitness.in/">zebrafitness.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://kaufetronics.com/">kaufetronics.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://srisaibakthi.org/">srisaibakthi.org</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://chilinn.in/">chilinn.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://crawllane.com/">crawllane.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://relianceinstruments.com/">relianceinstruments.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://blasta.in/">blasta.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://alltechzsolutions.in/">alltechzsolutions.in</a></h4>
              </div>
            </div>
            <div className="section-title">
              <br></br>
              <div style={{ backgroundColor: "black" }}>
                <center><h4 style={{ color: "white" }}>DIGITAL MARKETING</h4></center>
              </div>
              <br></br>
              <div className="section-title">
                <h3 className="resume-title"><b>Altus Quantum Business Solutions Private Limited</b></h3>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://altusquantum.com/">altusquantum.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://cascaderestaurant.in/">cascaderestaurant.in</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://chineseblacktigerkungfutaichi.com/">chineseblacktigerkungfutaichi.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://fooodshooters.com/">fooodshooters.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://anjappar.com/">anjappar.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://primeindianhospitals.com/">primeindianhospitals.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://phire.in/">phire.in</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://pupildiner.in/">pupildiner.in</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://drpadmapriya.in/">drpadmapriya.in</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://yashscientifics.com/">yashscientifics.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://slicerooms.com/">slicerooms.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://uterinetransplant.com/">uterinetransplant.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://enspiresolutions.ae/">enspiresolutions.ae</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://satorie.in/">satorie.in</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://vivekortho.com/">vivekortho.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://mangotreefood.com/">mangotreefood.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://phiferindia.com/">phiferindia.com</a></h4>
                </div>
                <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                  <div className="icon"><i className="bi bi-globe"></i></div>
                  <h4 className="title"><a target="_blank" href="https://joylivercare.com/">joylivercare.com</a></h4>
                </div>
              </div>
              <hr></hr>
              <h3 className="resume-title"><b>SV International Technologies</b></h3>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://techsv.in/">techsv.in</a></h4>
              </div>
            </div>
            <hr></hr>
            <div className="section-title">
              <h3 className="resume-title"><b>Intelliers Global Services</b></h3>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://intelliers.com/">intelliers.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://nolte-kitchens.com/">nolte-kitchens.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://cadd.in/">cadd.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://savingsindia.in/">savingsindia.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://justbuy.lk/">justbuy.lk</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://cadd.co.in/">cadd.co.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://stayfront.com/">stayfront.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://milleniumsystem.com/">milleniumsystem.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://livewireindia.com/">livewireindia.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://ayurillam.in/">ayurillam.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://caddschool.com/">caddschool.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://synergybs.in/">synergybs.in</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://diasem.com/">diasem.com</a></h4>
              </div>
              <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                <div className="icon"><i className="bi bi-globe"></i></div>
                <h4 className="title"><a target="_blank" href="https://caddschool.in/">caddschool.in</a></h4>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div >
  );
}

export default Projects;
